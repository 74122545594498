@app.directive 'bannerStudioPaywall', (PricingService, AnalyticsService, csConfig, ClassesService, ModalService, $timeout) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'bannerStudioPaywall'
  replace: true
  controller:( $scope) ->
    @isStudioLive = csConfig.isStudioLive
    type = if @isStudioLive || @forceStudio || @source == 'class' then 'studio' else 'premium'
    defaultStudio = {
      title: "Unlock this content with a Studio Pass subscription."
      description: "Get unlimited access to all of ChefSteps with Studio Pass."
      terms: 'By purchasing this class, I agree to the ChefSteps <a href="https://www.breville.com/us/en/legal/terms-of-use.html" target="_blank">terms of use</a> and <a href="/privacy">privacy policy.</a>'
      buttonConf: {type:'standard', title:'Get Studio Pass', url: '/studiopass', theme:'solid orange', context: 'Activity Paywall'}
      orderButton: { enabled: 'false', conf: {} },
      bullets: [
        {
          listContent: '<strong>“Absolutely! Where do I sign up?”</strong> <br/>Just <a class="underlined-link" title="SSO" href="/sso">click here</a>, and we\'ll get you started.'
        },
        {
          listContent: '<strong>“Hmm... show me more.”</strong> <br/> <a class="underlined-link" title="studiopass" href="https://www.chefsteps.com/studiopass">We\'re happy to!</a>'
        },
        {
          listContent: '<strong>“Hold on! I\'m already a subscriber.”</strong> <br/> Yikes! Sorry for the inconvenience. <a class="underlined-link" title="support" href="https://support.chefsteps.com/hc/en-us/articles/9957930003095-Why-am-I-seeing-the-paywall-screen-when-I-have-a-Studio-Pass-membership">Let’s get this sorted</a>.'
        }
      ]
    }
    defaultPremium = {
      title: "Unlock ChefSteps Premium"
      description: "Includes classes, and special offers. <a href='/premium'>Learn more.</a>"
      terms: 'By purchasing this class, I acknowledge to <a href="/terms">ChefSteps Terms of Use</a> and <a href="/privacy">Privacy Policy.</a>'
      buttonConf: {type:'standard', title:'Buy now', url: '/premium', theme:'solid orange', context: 'Activity Paywall'}
      orderButton: { enabled: 'false', conf: {} }
    }

    initMeta = =>
      @meta = {
        studio:  _.cloneDeep defaultStudio
        premium: _.cloneDeep defaultPremium
      }[type]

    initMeta()

    @trackMoreInfo = ->
      AnalyticsService.track 'Banner Clicked', {name: 'bannerStudioPaywall'}

    @login = ->
      ModalService.open('login',
        source: 'payWallButton'
      ).then(
        -> $state.go 'main.activity'
      )

    ClassesService.find(@orderReferrer, @sourceUri).then (result) =>
      data = _.get(result, 'data')
      if data
        @meta.orderButton.conf = { type: 'subscription', title: "Buy this class: #{data.price}", theme: 'outline dark', planId: data.plan, isOrder: true, referrer: data.slug, context: 'Activity Paywall' }
        @meta.buttonConf = {type:'standard', title:'Get Studio Pass', url: '/studiopass', theme:'outline dark'}
        if @source == 'activity' && data.price
          @meta.orderButton.enabled = 'true'
          @meta.bullets = undefined
          @meta.title = "Cook smarter with ChefSteps classes."
          @meta.description = "Buy this class à la carte or unlock access to all classes with a Studio Pass subscription."
          @meta.buttonConf['context'] = 'Activity Paywall'
          if _.get(data,'included_in_other_class')
            lesson = _.get(data, 'classes', {})
            @meta.title = "Score!"
            @meta.terms = null
            @meta.description = "You already have access within your <a href='#{lesson.url}'> #{lesson.title} </a> class or access all our classes with  <a href='/studiopass'>ChefSteps Studio Pass.</a>"
            @meta.orderButton.conf = {type:'standard', title:'View lesson', url: "/activities/#{@sourceUri}?context=#{lesson.slug}", theme:'outline dark', context: 'Activity Paywall'}
        else if @source == 'class'
          @meta.orderButton.enabled = 'true'
          @meta.bullets = undefined
          @meta.title = "See what’s cooking."
          @meta.description = "Level-up your cooking skills by purchasing our Classes a-la-carte, <br>or become a <a href='/studiopass'>ChefSteps Studio Pass</a> member and access all our classes."
          @meta.buttonConf['context'] = 'Class Paywall'
      else
        initMeta()
    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
    forceStudio: '='
    source: '='
    sourceUri: '='
    orderReferrer: '='
  }
  templateUrl: "/assets/templates/components/banner/bannerStudioPaywall/bannerStudioPaywall.html"
