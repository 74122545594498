@app.directive 'infoIcon', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'infoIcon'
  controller: ->
    this

  bindToController: {
    color: '='
  }
  template: """
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10 3.5C7.65625 3.5 5.53125 4.75 4.34375 6.75C3.1875 8.78125 3.1875 11.25 4.34375 13.25C5.53125 15.2812 7.65625 16.5 10 16.5C12.3125 16.5 14.4375 15.2812 15.625 13.25C16.7812 11.25 16.7812 8.78125 15.625 6.75C14.4375 4.75 12.3125 3.5 10 3.5ZM10 18C7.125 18 4.5 16.5 3.0625 14C1.625 11.5312 1.625 8.5 3.0625 6C4.5 3.53125 7.125 2 10 2C12.8438 2 15.4688 3.53125 16.9062 6C18.3438 8.5 18.3438 11.5312 16.9062 14C15.4688 16.5 12.8438 18 10 18ZM8.75 12.5H9.5V10.5H8.75C8.3125 10.5 8 10.1875 8 9.75C8 9.34375 8.3125 9 8.75 9H10.25C10.6562 9 11 9.34375 11 9.75V12.5H11.25C11.6562 12.5 12 12.8438 12 13.25C12 13.6875 11.6562 14 11.25 14H8.75C8.3125 14 8 13.6875 8 13.25C8 12.8438 8.3125 12.5 8.75 12.5ZM10 8C9.4375 8 9 7.5625 9 7C9 6.46875 9.4375 6 10 6C10.5312 6 11 6.46875 11 7C11 7.5625 10.5312 8 10 8Z' fill='{{infoIcon.color || "#046B99"}}'/>
    </svg>
  """
]
